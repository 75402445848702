import { findKey, isEmpty } from 'lodash';
import { generatePath, ParamParseKey } from 'react-router-dom';
import { AllowedPortal, ANY, Portals } from './content/portal/portals';
import { matchRoutes, useLocation } from './router';

export * from './router';
export { useExternalNavigate } from './utils/useExternalNavigate';
export type { NavigateOptions, To } from 'react-router-dom';

export type RouteType = Readonly<[string, AllowedPortal]>;

const findCarePortals = ANY(Portals.Loca, Portals.LocaWellness);

export const routes = {
  accountSettings: ['/account-settings', Portals.Hub],
  campusResources: ['/campus-resources', Portals.Hub],
  coaching: ['/coaching', Portals.Coaching],
  coachingAssessment: ['/coaching/assess/:assessmentType/:slide', Portals.Coaching],
  coachingChat: ['/coaching/chat', Portals.Coaching],
  coachingOnboarding: ['/coaching/onboard', Portals.Coaching],
  connectNowConfirmInfo: ['/connect-now/confirm-info', Portals.ConnectNow],
  connectNowHome: ['/connect-now', Portals.ConnectNow],
  connectNowJoinCall: ['/connect-now/join-call', Portals.ConnectNow],
  connectNowInQueue: ['/connect-now/in-queue', Portals.ConnectNow],
  connectNowPreEncounterForm: ['/connect-now/pre-encounter-form', Portals.ConnectNow],
  crisisResources: ['/crisis-resources', Portals.Hub],
  deepLinkPage: ['/deep-link', Portals.Public], // Deprecated, use loginDeepLink instead.
  findCareAssessments: ['/find-care/assess/:assessmentType/:slide', findCarePortals],
  findCareConfirmInfo: ['/find-care/confirm-info', findCarePortals],
  findCareCrisisSupport: ['/find-care/crisis-support/:slide', findCarePortals],
  findCareDemographics: ['/find-care/demographics', findCarePortals],
  findCarePreQuantitative: ['/find-care/pre-quantitative', findCarePortals],
  findCarePreTriage: ['/find-care/pre-triage', findCarePortals],
  findCareStartCare: ['/find-care/start', findCarePortals],
  findCareRecommendation: ['/find-care/recommendation', findCarePortals],
  findCareReviewAnswers: ['/find-care/review-answers', findCarePortals],
  forwardOnPortal: ['/forward/*', Portals.Hub],
  home: ['/home', Portals.Hub],
  login: ['/login', Portals.Public],
  loginActivationResend: ['/login/activation/resend', Portals.Public],
  loginDeepLink: ['/login/deep-link', Portals.Public],
  loginFromPortal: ['/login/callback', Portals.Public],
  loginResetPassword: ['/login/reset', Portals.Public],
  loginNewPassword: ['/login/reset/new', Portals.Public],
  loginSsoResponse: ['/login/sso/response', Portals.Public],
  logout: ['/logout', Portals.Public],
  module: ['/m/:moduleId', Portals.Skills],
  mrn: ['/mrn', Portals.Public],
  mrnExternal: ['/external-mrn', Portals.Public],
  notFound: ['*', Portals.Public],
  onboarding: ['/onboarding', Portals.Hub],
  onboardingPhoneNumber: ['/onboarding/phone-number', Portals.Hub],
  onDemandConfirmInfo: ['/on-demand/confirm-info', Portals.Odes],
  onDemandHome: ['/on-demand', Portals.Odes],
  onDemandJoinCall: ['/on-demand/join-call', Portals.Odes],
  onDemandInQueue: ['/on-demand/in-queue', Portals.Odes],
  onDemandPreEncounterForm: ['/on-demand/pre-encounter-form', Portals.Odes],
  patientPortal: ['/patient-portal', ANY(Portals.Therapy, Portals.Psychiatry)],
  patientPortalRedirect: ['/patient-portal/redirect', Portals.Public],
  reflection: ['/reflection/:reflectionId', Portals.Skills],
  reflections: ['/reflections', Portals.Skills],
  reschedule: ['/reschedule/:appointmentId', ANY(Portals.Coaching, Portals.Loca)],
  selfCareHome: ['/self-care', Portals.Skills],
  selfCareQuiz: ['/self-care/quiz/:slide', Portals.Skills],
  signup: ['/signup', Portals.Public],
  signupFinish: ['/signup/finish', Portals.Public],
  skill: ['/m/:moduleId/skill/:skillId/:slide', Portals.Skills],
  skillPathway: ['/skill/pathway', Portals.Skills],
  skillPathwaySection: ['/skill/pathway/m/:moduleId/s/:sectionId', Portals.Skills],
  start: ['/', Portals.Public],
  therapy: ['/therapy', Portals.NonLocaTherapyGateway],
  therapyEligibility: ['/therapy/eligibility', Portals.Hub],
  tour: ['/tour/:slide', Portals.Loca],
} as const;

export type RouteKeys = keyof typeof routes;
export type RoutableKeys = Exclude<RouteKeys, 'notFound'>;

type RoutePaths = {
  [a in keyof typeof routes]: (typeof routes)[a][0];
};

type RouteParamNames<RouteName extends RouteKeys> = ParamParseKey<RoutePaths[RouteName]>;
export type RouteParams<RouteName extends RouteKeys> = {
  [key in RouteParamNames<RouteName>]: string;
};

export const parseRouteName = (location: ReturnType<typeof useLocation>): RouteKeys => {
  const searchableRoutes = Object.values(routes).map(([routePath]) => ({ path: routePath }));

  const matches = matchRoutes(searchableRoutes, location);

  const matchedRoute = matches?.[0]?.route;
  if (!matchedRoute) {
    return 'notFound';
  }

  const matchedRouteName =
    findKey(routes, ([routePath]) => routePath === matchedRoute.path) ?? 'notFound';

  return matchedRouteName as RouteKeys;
};

export const useCurrentRouteName = (): RouteKeys => {
  const location = useLocation();

  return parseRouteName(location);
};

export const useCurrentRouteSearch = (): Record<string, string> => {
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  // Convert the search entries iterator to an object.
  return Object.fromEntries(search.entries());
};

export const getRoute = <
  RouteName extends Exclude<RouteKeys, 'notFound'>,
  RouteParamValues extends RouteParams<RouteName>,
  RoutePath extends RoutePaths[RouteName],
>(
  routeName: RouteName,
  paramValues: RouteParamValues,
  searchParamValues?: ConstructorParameters<typeof URLSearchParams>[0],
): string => {
  const routePath = routes[routeName][0] as RoutePath;
  const fullPath = generatePath(routePath, paramValues);

  if (searchParamValues !== undefined) {
    const searchParams = new URLSearchParams(searchParamValues);
    return `${fullPath}?${searchParams.toString()}`;
  }

  return fullPath;
};

const isLocationPublic = (location: ReturnType<typeof useLocation>): boolean => {
  const searchableRoutes = Object.values(routes).map(([routePath]) => ({ path: routePath }));

  const matches = matchRoutes(searchableRoutes, location);

  const matchedRoute = matches?.[0]?.route;
  if (!matchedRoute) {
    return true;
  }

  const matchedRouteName = findKey(routes, ([routePath]) => routePath === matchedRoute.path);

  if (matchedRouteName === undefined) {
    // The not found page is technically public.
    return true;
  }

  const routePortal = routes[matchedRouteName as RouteKeys][1];

  return routePortal === Portals.Public;
};

export const useIsCurrentRoutePublic = (): boolean => {
  const location = useLocation();

  return isLocationPublic(location);
};

export const appendSearchToRoute = (
  fullPath: string,
  newSearchParams: Record<string, string>,
): string => {
  if (isEmpty(newSearchParams)) {
    return fullPath;
  }

  const searchParams = new URLSearchParams(newSearchParams);

  // Does not support routes with hashes...
  const appender = fullPath.includes('?') ? '&' : '?';
  return `${fullPath}${appender}${searchParams.toString()}`;
};
